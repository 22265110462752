import React, { Suspense } from "react";
import classNames from "classnames";
import styles from "./Icon.module.scss";

const Facebook = React.lazy(() => import("./Icons/Facebook"));
const Instagram = React.lazy(() => import("./Icons/Instagram"));

const allIcons = {
  facebook: Facebook,
  instagram: Instagram
};

const Icon = ({ icon = "", modifiers = [], dimensions = {} }) => {
  const Svg = allIcons[icon];

  return (
    <span
      className={classNames(
        styles["Icon"],
        modifiers.map((mod) => styles[`Icon--${mod}`])
      )}
      style={
        dimensions
          ? { width: dimensions.width, height: dimensions.height }
          : undefined
      }
    >
      {Svg && (
        <Suspense fallback={<span className={styles["Icon--loading"]} />}>
          <Svg />
        </Suspense>
      )}
    </span>
  );
};

export default Icon;
