import React from "react";
import Icon from "../Icon/Icon";
import Logo from "../Logo/Logo";
import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={styles["Root"]}>
      <div className={styles["Root__Grid"]}>
        <div className={styles["Root__Column"]}>
          <div className={styles["Root__Logo"]}>
            <a href="/">
              <Logo />
              <span className={"sr-only"}>Back home</span>
            </a>
          </div>
          <ul className={styles["Root__List"]}>
            <li className={styles["Root__Item"]}>Martin Karlsson</li>
            <li className={styles["Root__Item"]}>
              <a
                className={styles["Root__Link"]}
                href="mailto:info@concertsalive.se"
              >
                info@concertsalive.se
              </a>
            </li>
          </ul>
          <div className={styles["Root__Social"]}>
            <a
              className={styles["Root__Icon"]}
              href="https://facebook.com/concertsaliveonline"
            >
              <Icon icon={"facebook"} />
              <span className={"sr-only"}>Facebook</span>
            </a>
            <a
              className={styles["Root__Icon"]}
              href="https://instagram.com/concertsalive"
            >
              <Icon icon={"instagram"} />
              <span className={"sr-only"}>Instagram</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
