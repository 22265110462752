// import styles from "./Logo.module.scss";

const Logo = ({}) => {
  return (
    <svg viewBox="0 0 125 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_13_211)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.6117 0H68.4746V3.92C64.5145 3.92 60.5373 3.92 56.5772 3.92C56.5772 2.64772 56.5772 1.39263 56.5772 0.120351C56.5772 0.0687719 56.56 0 56.6117 0Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1702 5.60491H26.0331V9.52491C22.0731 9.52491 18.0958 9.52491 14.1357 9.52491C14.1357 8.25263 14.1357 6.99754 14.1357 5.72526C14.1357 5.67368 14.1357 5.6221 14.1702 5.60491Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.6117 5.60491H68.4746V9.52491C64.5145 9.52491 60.5373 9.52491 56.5772 9.52491C56.5772 8.25263 56.5772 6.99754 56.5772 5.72526C56.5772 5.67368 56.56 5.6221 56.6117 5.60491Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1702 11.227H26.0331V15.147C22.0731 15.147 18.0958 15.147 14.1357 15.147C14.1357 13.8747 14.1357 12.6196 14.1357 11.3474C14.1357 11.2958 14.1357 11.227 14.1702 11.227Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.6117 11.227H68.4746V15.147C64.5145 15.147 60.5373 15.147 56.5772 15.147C56.5772 13.8747 56.5772 12.6196 56.5772 11.3474C56.5772 11.2958 56.56 11.227 56.6117 11.227Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.7474 11.227H82.6103V15.147C78.6503 15.147 74.673 15.147 70.7129 15.147C70.7129 13.8747 70.7129 12.6196 70.7129 11.3474C70.6957 11.2958 70.6957 11.227 70.7474 11.227Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1702 16.8835H26.0331V20.8035C22.0731 20.8035 18.0958 20.8035 14.1357 20.8035C14.1357 19.5312 14.1357 18.2761 14.1357 17.0039C14.1357 16.9351 14.1357 16.8835 14.1702 16.8835Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3057 16.8835H40.1687V20.8035C36.2086 20.8035 32.2314 20.8035 28.2713 20.8035C28.2713 19.5312 28.2713 18.2761 28.2713 17.0039C28.2713 16.9351 28.2541 16.8835 28.3057 16.8835Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.6117 16.8835H68.4746V20.8035C64.5145 20.8035 60.5373 20.8035 56.5772 20.8035C56.5772 19.5312 56.5772 18.2761 56.5772 17.0039C56.5772 16.9351 56.56 16.8835 56.6117 16.8835Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.7474 16.8835H82.6103V20.8035C78.6503 20.8035 74.673 20.8035 70.7129 20.8035C70.7129 19.5312 70.7129 18.2761 70.7129 17.0039C70.6957 16.9351 70.6957 16.8835 70.7474 16.8835Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.0186 16.8835H110.882V20.8035C106.922 20.8035 102.944 20.8035 98.9842 20.8035C98.9842 19.5312 98.9842 18.2761 98.9842 17.0039C98.967 16.9351 98.967 16.8835 99.0186 16.8835Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.0187 22.454H110.83C110.933 23.6919 110.847 25.1018 110.864 26.4084C106.973 26.5116 102.91 26.4256 98.967 26.4428C98.967 25.1533 98.967 23.8639 98.967 22.5572C98.967 22.5056 98.967 22.454 99.0187 22.454Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1702 22.4884H26.0331V26.4084C22.0731 26.4084 18.0958 26.4084 14.1357 26.4084C14.1357 25.1361 14.1357 23.881 14.1357 22.6088C14.1357 22.5572 14.1357 22.4884 14.1702 22.4884Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3057 22.4884H40.1687V26.4084C36.2086 26.4084 32.2314 26.4084 28.2713 26.4084C28.2713 25.1361 28.2713 23.881 28.2713 22.6088C28.2713 22.5572 28.2541 22.4884 28.3057 22.4884Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.6117 22.4884H68.4746V26.4084C64.5145 26.4084 60.5373 26.4084 56.5772 26.4084C56.5772 25.1361 56.5772 23.881 56.5772 22.6088C56.5772 22.5572 56.56 22.4884 56.6117 22.4884Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.7474 22.4884H82.6103V26.4084C78.6503 26.4084 74.673 26.4084 70.7129 26.4084C70.7129 25.1361 70.7129 23.881 70.7129 22.6088C70.6957 22.5572 70.6957 22.4884 70.7474 22.4884Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1702 28.0933H26.0331V32.0133C22.0731 32.0133 18.0958 32.0133 14.1357 32.0133C14.1357 30.7411 14.1357 29.486 14.1357 28.2137C14.1357 28.1621 14.1357 28.1105 14.1702 28.0933Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3057 28.0933H40.1687V32.0133C36.2086 32.0133 32.2314 32.0133 28.2713 32.0133C28.2713 30.7411 28.2713 29.486 28.2713 28.2137C28.2713 28.1621 28.2541 28.1105 28.3057 28.0933Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.6117 28.0933H68.4746V32.0133C64.5145 32.0133 60.5373 32.0133 56.5772 32.0133C56.5772 30.7411 56.5772 29.486 56.5772 28.2137C56.5772 28.1621 56.56 28.1105 56.6117 28.0933Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.7474 28.0933H82.6103V32.0133C78.6503 32.0133 74.673 32.0133 70.7129 32.0133C70.7129 30.7411 70.7129 29.486 70.7129 28.2137C70.6957 28.1621 70.6957 28.1105 70.7474 28.0933Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.8829 28.0933H96.7458V32.0133C92.7858 32.0133 88.8085 32.0133 84.8484 32.0133C84.8484 30.7411 84.8484 29.486 84.8484 28.2137C84.8312 28.1621 84.8312 28.1105 84.8829 28.0933Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.0186 28.0933H110.882V32.0133C106.922 32.0133 102.944 32.0133 98.9842 32.0133C98.9842 30.7411 98.9842 29.486 98.9842 28.2137C98.967 28.1621 98.967 28.1105 99.0186 28.0933Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1702 33.7154H26.0331V37.6354C22.0731 37.6354 18.0958 37.6354 14.1357 37.6354C14.1357 36.3632 14.1357 35.1081 14.1357 33.8358C14.1357 33.7842 14.1357 33.7154 14.1702 33.7154Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3057 33.7154H40.1687V37.6354C36.2086 37.6354 32.2314 37.6354 28.2713 37.6354C28.2713 36.3632 28.2713 35.1081 28.2713 33.8358C28.2713 33.7842 28.2541 33.7154 28.3057 33.7154Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.4415 33.7154H54.3044V37.6354C50.3444 37.6354 46.3671 37.6354 42.407 37.6354C42.407 36.3632 42.407 35.1081 42.407 33.8358C42.3898 33.7842 42.3898 33.7154 42.4415 33.7154Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.6117 33.7154H68.4746V37.6354C64.5145 37.6354 60.5373 37.6354 56.5772 37.6354C56.5772 36.3632 56.5772 35.1081 56.5772 33.8358C56.5772 33.7842 56.56 33.7154 56.6117 33.7154Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.7474 33.7154H82.6103V37.6354C78.6503 37.6354 74.673 37.6354 70.7129 37.6354C70.7129 36.3632 70.7129 35.1081 70.7129 33.8358C70.6957 33.7842 70.6957 33.7154 70.7474 33.7154Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.8829 33.7154H96.7458V37.6354C92.7858 37.6354 88.8085 37.6354 84.8484 37.6354C84.8484 36.3632 84.8484 35.1081 84.8484 33.8358C84.8312 33.7842 84.8312 33.7154 84.8829 33.7154Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.0186 33.7154H110.882V37.6354C106.922 37.6354 102.944 37.6354 98.9842 37.6354C98.9842 36.3632 98.9842 35.1081 98.9842 33.8358C98.967 33.7842 98.967 33.7154 99.0186 33.7154Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.137 33.7154H125V37.6354C121.04 37.6354 117.063 37.6354 113.103 37.6354C113.103 36.3632 113.103 35.1081 113.103 33.8358C113.103 33.7842 113.103 33.7154 113.137 33.7154Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.0515686 39.3719H11.9145V43.2919C7.95446 43.2919 3.97719 43.2919 0.0171334 43.2919C0.0171334 42.0197 0.0171334 40.7646 0.0171334 39.4923C-8.42367e-05 39.4235 -8.42349e-05 39.3719 0.0515686 39.3719Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1702 39.3719H26.0331V43.2919C22.0731 43.2919 18.0958 43.2919 14.1357 43.2919C14.1357 42.0197 14.1357 40.7646 14.1357 39.4923C14.1357 39.4235 14.1357 39.3719 14.1702 39.3719Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3057 39.3719H40.1687V43.2919C36.2086 43.2919 32.2314 43.2919 28.2713 43.2919C28.2713 42.0197 28.2713 40.7646 28.2713 39.4923C28.2713 39.4235 28.2541 39.3719 28.3057 39.3719Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.4415 39.3719H54.3044V43.2919C50.3444 43.2919 46.3671 43.2919 42.407 43.2919C42.407 42.0197 42.407 40.7646 42.407 39.4923C42.3898 39.4235 42.3898 39.3719 42.4415 39.3719Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.6117 39.3719H68.4746V43.2919C64.5145 43.2919 60.5373 43.2919 56.5772 43.2919C56.5772 42.0197 56.5772 40.7646 56.5772 39.4923C56.5772 39.4235 56.56 39.3719 56.6117 39.3719Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.7474 39.3719H82.6103V43.2919C78.6503 43.2919 74.673 43.2919 70.7129 43.2919C70.7129 42.0197 70.7129 40.7646 70.7129 39.4923C70.6957 39.4235 70.6957 39.3719 70.7474 39.3719Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.8829 39.3719H96.7458V43.2919C92.7858 43.2919 88.8085 43.2919 84.8484 43.2919C84.8484 42.0197 84.8484 40.7646 84.8484 39.4923C84.8312 39.4235 84.8312 39.3719 84.8829 39.3719Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.0186 39.3719H110.882V43.2919C106.922 43.2919 102.944 43.2919 98.9842 43.2919C98.9842 42.0197 98.9842 40.7646 98.9842 39.4923C98.967 39.4235 98.967 39.3719 99.0186 39.3719Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.137 39.3719H125V43.2919C121.04 43.2919 117.063 43.2919 113.103 43.2919C113.103 42.0197 113.103 40.7646 113.103 39.4923C113.103 39.4235 113.103 39.3719 113.137 39.3719Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.0515686 44.9768H11.9145V48.8968C7.95446 48.8968 3.97719 48.8968 0.0171334 48.8968C0.0171334 47.6246 0.0171334 46.3695 0.0171334 45.0972C-8.42367e-05 45.0456 -8.42349e-05 44.9768 0.0515686 44.9768Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1702 44.9768H26.0331V48.8968C22.0731 48.8968 18.0958 48.8968 14.1357 48.8968C14.1357 47.6246 14.1357 46.3695 14.1357 45.0972C14.1357 45.0456 14.1357 44.9768 14.1702 44.9768Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3057 44.9768H40.1687V48.8968C36.2086 48.8968 32.2314 48.8968 28.2713 48.8968C28.2713 47.6246 28.2713 46.3695 28.2713 45.0972C28.2713 45.0456 28.2541 44.9768 28.3057 44.9768Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.4415 44.9768H54.3044V48.8968C50.3444 48.8968 46.3671 48.8968 42.407 48.8968C42.407 47.6246 42.407 46.3695 42.407 45.0972C42.3898 45.0456 42.3898 44.9768 42.4415 44.9768Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.6117 44.9768H68.4746V48.8968C64.5145 48.8968 60.5373 48.8968 56.5772 48.8968C56.5772 47.6246 56.5772 46.3695 56.5772 45.0972C56.5772 45.0456 56.56 44.9768 56.6117 44.9768Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.7474 44.9768H82.6103V48.8968C78.6503 48.8968 74.673 48.8968 70.7129 48.8968C70.7129 47.6246 70.7129 46.3695 70.7129 45.0972C70.6957 45.0456 70.6957 44.9768 70.7474 44.9768Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.8829 44.9768H96.7458V48.8968C92.7858 48.8968 88.8085 48.8968 84.8484 48.8968C84.8484 47.6246 84.8484 46.3695 84.8484 45.0972C84.8312 45.0456 84.8312 44.9768 84.8829 44.9768Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.0186 44.9768H110.882V48.8968C106.922 48.8968 102.944 48.8968 98.9842 48.8968C98.9842 47.6246 98.9842 46.3695 98.9842 45.0972C98.967 45.0456 98.967 44.9768 99.0186 44.9768Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.137 44.9768H125V48.8968C121.04 48.8968 117.063 48.8968 113.103 48.8968C113.103 47.6246 113.103 46.3695 113.103 45.0972C113.103 45.0456 113.103 44.9768 113.137 44.9768Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default Logo;
