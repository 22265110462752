import styles from "./Contact.module.scss";

const Contact = () => {
  return (
    <div className={styles["Root"]}>
      <h2 id={"contact"} className={styles["Root__Title"]}>
        Contact
      </h2>
      <p className={styles["Root__Text"]}>
        Looking to book any of our artists or just wanna get in touch?
      </p>
      <p className={styles["Root__Text"]}>
        Send us a <a href="mailto:info@concertsalive.se">mail</a> or hit us up
        on <a href="https://instagram.com/concertsalive">Instagram</a> or{" "}
        <a href="https://facebook.com/concertsaliveonline">Facebook</a>.
      </p>
    </div>
  );
};

export default Contact;
