import React, { useState, useEffect } from "react";
import { FocusTrap } from "focus-trap-react";
import classNames from "classnames";

import Logo from "../Logo/Logo";
import styles from "./Header.module.scss";

const Header = ({}) => {
  const items = [
    {
      title: "About us",
      url: "#about-us"
    },
    {
      title: "Artists",
      url: "#artists"
    },
    {
      title: "Contact",
      url: "#contact"
    }
  ];

  return (
    <header className={styles["Root"]}>
      <div className={styles["Root__Logo"]}>
        <a href="/">
          <Logo />
          <span className={"sr-only"}>{"Back home"}</span>
        </a>
      </div>
      <Navigation items={items} />
    </header>
  );
};

const Navigation = ({ items = [] }) => {
  const [isExpanded, setExpanded] = useState(false);

  const classes = classNames(styles["Navigation"], {
    [styles["Navigation--IsExpanded"]]: isExpanded
  });

  useEffect(() => {
    const { hash } = window.location;
    if (hash !== "") {
      window.location = hash;
    }
  }, []);

  const handleClick = (e, href) => {
    e.preventDefault();
    setExpanded(false);

    setTimeout(() => {
      window.location = href;
    }, 100);
  };

  return (
    <FocusTrap active={isExpanded}>
      <div className={classes}>
        <button
          className={styles["Navigation__Button"]}
          onClick={() => setExpanded(!isExpanded)}
          aria-expanded={isExpanded}
          aria-controls="navigation"
        >
          <span className={styles["Navigation__Line"]}></span>
          <span className={styles["Navigation__Line"]}></span>
          <span className={styles["Navigation__Line"]}></span>
          <span className="sr-only">
            {isExpanded ? "Close menu" : "Open menu"}
          </span>
        </button>

        <nav id="navigation" className={styles["Navigation__Navigation"]}>
          <ul className={styles["Navigation__Menu"]}>
            {items.map((item, index) => (
              <li key={index} className={styles["Navigation__Item"]}>
                <button
                  className={styles["Navigation__Link"]}
                  onClick={(e) => handleClick(e, item.url)}
                >
                  {item.title}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </FocusTrap>
  );
};

export default Header;
