import styles from "./AboutUs.module.scss";

const AboutUs = () => {
  return (
    <div className={styles["Root"]}>
      <p id={"about-us"} className={styles["Root__Text"]}>
        Concerts Alive, founded in 2017, is a booking agency based in Sweden.
        Since the beginning we have booked countless concerts for artists such
        as Airbourne, Saxon, The Darkness and many others.
      </p>
      <p className={styles["Root__Text"]}>
        Since 2022, Concerts Alive has been collaborating with the Swedish
        festival Skogsröjet.
      </p>
      <ul className={styles["Root__List"]}>
        <li className={styles["Root__Item"]}>
          “I’ve known Martin since he was a teenager and with the same
          will,tenacity and conviction he had then, he now runs Concerts Alive.
          We have found a perfect formula for my wishes as an artist.”
          <span className={styles["Root__Quote"]}>
            – Nicke Borg, Backyard Babies
          </span>
        </li>
        <li className={styles["Root__Item"]}>
          “Happy to see that the torch of rock ‘n’ roll has been passed on to
          the next generation with guys like Martin Karlsson. His agency
          Concerts Alive understand that it takes not only hard work but also
          dedication and professionalism. I am very happy with the effort he has
          contributed to getting me out there performing live. I look forward to
          many more shows!”
          <span className={styles["Root__Quote"]}>
            – Ryan Roxie, Alice Cooper Band
          </span>
        </li>
        <li className={styles["Root__Item"]}>
          “Martin is awesome! He is always available and he is constantly
          booking shows. In addition, there is always a good, ongoing dialogue
          that drives everything forward all the time. The name of his company
          could not be more fitting.”
          <span className={styles["Root__Quote"]}>
            – Conny Bloom, Electric Boys, ex Hanoi Rocks
          </span>
        </li>
        <li className={styles["Root__Item"]}>
          “Martin is the consumate professional coupled with personal warmth and
          dedication. For a band like Prins Svart, it is a luxury to be
          represented by Concerts Alive. We view any given opportunity with the
          same eyes, which makes working together fun and stress-free.”
          <span className={styles["Root__Quote"]}>
            – Henrik Bergqvist, Prins Svart, ex The Poodles
          </span>
        </li>
      </ul>
    </div>
  );
};

export default AboutUs;
