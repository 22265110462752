// import Image from "next/image";

import Image from "../Image/Image";
import styles from "./Artists.module.scss";

import Alien from "../../assets/images/alien.webp";
import Ballbreaker from "../../assets/images/ballbreaker.webp";
import BlackIngvars from "../../assets/images/black_ingvars.webp";
import BonnieTyler from "../../assets/images/bonnie_tyler.webp";
import Bullet from "../../assets/images/bullet.webp";
import Candlemass from "../../assets/images/candlemass.webp";
import CicciLanden from "../../assets/images/cicci_landen.webp";
import CirkusPrutz from "../../assets/images/cirkus_prutz.webp";
import ElectricBoys from "../../assets/images/electric_boys.webp";
import Eradikated from "../../assets/images/eradikated.webp";
import HandesomeDickManitoba from "../../assets/images/handsome_dick_manitoba.webp";
import Heat from "../../assets/images/heat.webp";
import Hetfieldz from "../../assets/images/hetfieldz.webp";
import Hexed from "../../assets/images/hexed.webp";
import JanJohansen from "../../assets/images/jan_johansen.webp";
import JesperBinzerBand from "../../assets/images/jesper_binzer_band.webp";
import KikkiDanielsson from "../../assets/images/kikki_danielsson.webp";
import MarcoMendoza from "../../assets/images/marco_mendoza.webp";
import MartinAlmgren from "../../assets/images/martin_almgren.webp";
import MikkeyDee from "../../assets/images/mikkey_dee.webp";
import NashvillePussy from "../../assets/images/nashville_pussy.webp";
import NickeBorg from "../../assets/images/nicke_borg.webp";
import PrinsSvart from "../../assets/images/prins_svart.webp";
import RonnyORagge from "../../assets/images/ronny_och_ragge.webp";
import RobbieWilliamsExperience from "../../assets/images/rwe.webp";
import RyanRoxie from "../../assets/images/ryan_roxie.webp";
import ShirleyClamp from "../../assets/images/shirley_clamp.webp";
import SisterSin from "../../assets/images/sister_sin.webp";
import Slade from "../../assets/images/slade.webp";
import Snowstorm from "../../assets/images/snowstorm.webp";
import Sorm from "../../assets/images/sorm.webp";
import Sweet from "../../assets/images/sweet.webp";
import TheBones from "../../assets/images/the_bones.webp";
import TheCocktailSlippers from "../../assets/images/the_cocktail_slippers.webp";
import TheGristle from "../../assets/images/the_gristle.webp";
import TheMercuryRiots from "../../assets/images/the_mercury_riots.webp";
import TheQuill from "../../assets/images/the_quill.webp";
import Treat from "../../assets/images/treat.webp";
import Wolf from "../../assets/images/wolf.webp";
import Wrethovs from "../../assets/images/wrethovs.webp";

const Artists = () => {
  const genres = [
    {
      title: "Rock / Metal",
      items: [
        {
          title: "Alien",
          url: "https://www.aliensweden.com",
          image: Alien,
          objectPosition: "50% 30%"
        },
        {
          title: "Black Ingvars",
          url: "http://www.blackingvars.se",
          image: BlackIngvars
        },
        {
          title: "Bonnie Tyler",
          url: "http://www.bonnietyler.com",
          image: BonnieTyler
        },
        {
          title: "Bullet",
          url: "https://www.bulletrock.com",
          image: Bullet
        },
        {
          title: "Candlemass",
          url: "https://www.candlemass.se",
          image: Candlemass
        },
        {
          title: "Cirkus Prütz",
          url: "https://www.facebook.com/cirkusprytz",
          image: CirkusPrutz,
          objectPosition: "50% 30%"
        },
        {
          title: "Cocktail Slippers",
          url: "https://thecocktailslippers.com/",
          image: TheCocktailSlippers
        },
        {
          title: "Handsome Dick Manitoba",
          url: "https://www.handsomedickmanitoba.com",
          image: HandesomeDickManitoba
        },
        {
          title: "Electric Boys",
          url: "http://www.electricboys.com",
          image: ElectricBoys
        },
        {
          title: "Eradikated",
          url: "https://www.eradikated.com",
          image: Eradikated
        },
        {
          title: "H.E.A.T",
          url: "https://www.heatsweden.com",
          image: Heat
        },
        {
          title: "Hexed",
          url: "https://hexed.se",
          image: Hexed
        },
        {
          title: "Jesper Binzer Band",
          url: "https://www.facebook.com/DAD.JesperBinzer",
          image: JesperBinzerBand
        },
        {
          title: "Marco Mendoza",
          url: "https://www.marcomendoza.com/",
          image: MarcoMendoza,
          objectPosition: "50% 30%"
        },
        {
          title: "Mikkey Dee",
          url: "https://www.mikkeydee.com",
          image: MikkeyDee,
          objectPosition: "50% 30%"
        },
        {
          title: "Nashville Pussy",
          url: "https://www.nashvillepussy.com",
          image: NashvillePussy
        },
        {
          title: "Nicke Borg",
          url: "http://www.nickeborg.com",
          image: NickeBorg
        },
        // {
        //   title: "Philip Shouse",
        //   url: "https://www.facebook.com/theephilipshouse",
        //   image: "/assets/images/philip_shouse.webp"
        // },
        {
          title: "Prins Svart",
          url: "https://www.prinssvart.se",
          image: PrinsSvart
        },
        {
          title: "Ryan Roxie",
          url: "https://www.ryanroxie.com",
          image: RyanRoxie
        },
        {
          title: "S.O.R.M",
          url: "https://www.sormsweden.com",
          image: Sorm
        },
        {
          title: "Sister Sin",
          url: "https://www.facebook.com/@SisterSin",
          image: SisterSin
        },
        {
          title: "Slade",
          url: "https://www.facebook.com/SladeBandOfficial",
          image: Slade
        },
        {
          title: "Snowstorm",
          url: "https://www.snowstorm.se",
          image: Snowstorm,
          objectPosition: "50% 0%"
        },
        {
          title: "Sweet",
          url: "https://www.thesweet.com",
          image: Sweet
        },
        {
          title: "The Bones",
          url: "https://www.facebook.com/thebonesswe",
          image: TheBones
        },
        {
          title: "The Gristle",
          url: "https://www.facebook.com/thegristle1749/",
          image: TheGristle
        },
        {
          title: "The Mercury Riots",
          url: "https://www.themercuryriots.com",
          image: TheMercuryRiots
        },
        {
          title: "The Quill",
          url: "https://www.thequill.se",
          image: TheQuill
        },
        {
          title: "Treat",
          url: "https://www.treatnews.se",
          image: Treat
        },
        {
          title: "Wolf",
          url: "https://www.wolf.nu",
          image: Wolf
        }
      ]
    },
    {
      title: "Tribute",
      items: [
        {
          title: "Ballbreaker",
          url: "http://www.ballbreakertheband.se",
          image: Ballbreaker
        },
        {
          title: "Hetfieldz",
          url: "https://www.hetfieldz.com",
          image: Hetfieldz
        },
        {
          title: "Robbie Williams Experience",
          url: "https://rwes.se/",
          image: RobbieWilliamsExperience
        },
        {
          title: "Wrethov's",
          url: "https://andreasgrufstedt.com/band/wrethovsbryanadamstribute",
          image: Wrethovs
        }
      ]
    },
    {
      title: "Other",
      items: [
        // {
        //   title: "Anna Sahlene",
        //   url: "https://annasahlene.com/maxschultztrio",
        //   image: "/assets/images/anna_sahlene.webp"
        // },
        {
          title: "Cicci Landén",
          url: "https://ciccilanden.se",
          image: CicciLanden
        },
        // {
        //   title: "Ellinor Springstrike & Gareth Nugent",
        //   url: "https://ellinorspringstrike.com",
        //   image: "/assets/images/ellinor_springstrike_gareth_nugent.webp"
        // },
        {
          title: "Jan Johansen",
          url: "https://www.janjohansen.se",
          image: JanJohansen
        },
        {
          title: "Kikki Danielsson",
          url: "https://www.facebook.com/KikkiDanielsson",
          image: KikkiDanielsson,
          objectPosition: "50% 25%"
        },
        {
          title: "Martin Almgren",
          url: "https://www.facebook.com/martinalmgrenofficial",
          image: MartinAlmgren,
          objectPosition: "50% 30%"
        },
        {
          title: "Ronny & Ragge",
          url: "https://www.instagram.com/ronnyochraggeofficial/",
          image: RonnyORagge
        },
        {
          title: "Shirley Clamp",
          url: "https://www.shirleyclamp.se",
          image: ShirleyClamp
        }
      ]
    }
  ];

  return (
    <div className={styles["Root"]}>
      <h2 id={"artists"} className={styles["Root__Title"]}>
        Artists
      </h2>
      {genres.map((genre, index) => (
        <section key={index} className={styles["Root__Section"]}>
          <h3 className={styles["Root__SectionTitle"]}>{genre.title}</h3>
          <div className={styles["Root__Grid"]}>
            {genre.items.map((item, index) => (
              <div key={index} className={styles["Root__Item"]}>
                <a className={styles["Root__Link"]} href={item.url}>
                  <span className={"sr-only"}>{item.title}</span>
                </a>
                <div className={styles["Root__Image"]}>
                  <Image
                    src={item.image}
                    alt={item.title}
                    style={{
                      objectFit: "cover",
                      objectPosition: !!item.objectPosition
                        ? item.objectPosition
                        : "center"
                    }}
                    sizes={"(min-width: 1680px) 640px"}
                  />
                </div>
                <div className={styles["Root__Content"]}>
                  <h4>{item.title}</h4>
                </div>
              </div>
            ))}
          </div>
        </section>
      ))}
    </div>
  );
};

export default Artists;
