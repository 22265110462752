import React from "react";
import styles from "./Image.module.scss";

const Image = ({ src = "", alt = "", style = {} }) => {
  return (
    <div className={styles["Image"]}>
      <img src={src} alt={alt} style={style} />
    </div>
  );
};

export default Image;
