import AboutUs from "./components/AboutUs/AboutUs";
import Artists from "./components/Artists/Artists";
import Contact from "./components/Contact/Contact";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Logo from "./components/Logo/Logo";
import styles from "./App.module.scss";

function App() {
  return (
    <div className="App">
      <Header />
      <main className={styles["Root__Main"]}>
        <div className={styles["Root__Logo"]}>
          <Logo />
          <h1 className={"sr-only"}>Concerts Alive</h1>
        </div>
        <AboutUs />
        <Artists />
        <Contact />
      </main>
      <Footer />
    </div>
  );
}

export default App;
